import React from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { bakeStreetLogo } from "./common/constants";
import MenuIcon from "@mui/icons-material/Menu";
import { STORAGE_BUCKET } from "./common/constants";
import CloseIcon from "@mui/icons-material/Close";

const drawerWidth = 240;

export default function Header(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  let logo = (
    <Link component={RouterLink} underline="none" to={"/"} style={{ color: "inherit", cursor: "pointer" }}>
      <img src={bakeStreetLogo} style={{ height: "30px" }} />
    </Link>
  );

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <Box py={1} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box px={1}>{logo}</Box>
        <IconButton size="large">
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <List>
        <ListItem component={RouterLink} to={`/marketplace`} disablePadding sx={{ color: "#555" }}>
          <ListItemButton>
            <ListItemText primary={`Marketplace`} />
          </ListItemButton>
        </ListItem>
        <ListItem to={"/"} component="a" href="https://my.bakestreet.co/login" disablePadding sx={{ color: "#555" }}>
          <ListItemButton>
            <ListItemText primary={`Login`} />
          </ListItemButton>
        </ListItem>
        <ListItem component={RouterLink} to={`/book-demo`} disablePadding sx={{ color: "#555" }}>
          <ListItemButton>
            <ListItemText primary={`Book a Demo`} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        position="static"
        style={{
          color: "#555",
          backgroundColor: "#FEFEFE",
          paddingTop: "5px",
          paddingBottom: "5px",
          boxShadow: "none"
        }}
      >
        <Container maxWidth="xl">
          <Toolbar style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Box>{logo}</Box>
            <Box sx={{ flexGrow: 1, gap: "10px", display: { xs: "none", sm: "flex", justifyContent: "flex-end" } }}>
              <Button color="secondary" to={"/"} component="a" href="https://my.bakestreet.co/login">
                Login
              </Button>
              <Button color="primary" variant="contained" component={RouterLink} to={`/book-demo`}>
                Book a Demo
              </Button>
            </Box>
            <Box sx={{ flexGrow: 1, gap: "10px", display: { xs: "flex", sm: "none", justifyContent: "flex-end" } }}>
              <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ display: { sm: "none" } }}>
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth }
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}
