import React, { useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import BookDemoForm from "../components/BookDemoForm";
import Highlights from "../components/Highlights";

export default function BookDemo() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ backgroundColor: "#ECECEC" }}>
    <Container maxWidth="lg" >
      <Box pt={7} pb={10}>
        <Box mb={4} align="center">
          <Typography variant="h3" gutterBottom>
            Get a free BakeStreet demo
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Fill out the form to schedule a demo and no-pressure quote
          </Typography>
        </Box>
        <Grid container spacing={4}>
          <Grid item sm={3} xs={12}></Grid>
          <Grid item sm={6} xs={12}>
            <BookDemoForm />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Highlights />
          </Grid>
        </Grid>
      </Box>
    </Container>
    </Box>
  );
}
