import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Container, LinearProgress, Typography } from "@mui/material";
import bkstApi from "../api";
import OrderCancelationPolicy from "./component/OrderCancelationPolicy";
import OrderDetails from "./component/OrderDetails";
import OrderFulfillment from "./component/OrderFulfillment";
import { Alert, AlertTitle } from "@mui/material";
import OrderPayment from "./component/OrderPayment";
import Invoices from "./component/Invoices";
import OrderCareInstructions from "./component/OrderCareInstructions";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import IconText from "../common/IconText";
import BakerContactCard from "./component/BakerContactCard";
import OrderRefundPolicy from "./component/OrderRefundPolicy";
import LatePaymentPolicy from "./component/LatePaymentPolicy";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";

export default function Order(props) {
  const { orderId } = useParams();
  const [order, setOrder] = useState();

  useEffect(() => {
    bkstApi(`/order/${orderId}`).then((res) => {
      document.title = res.data.details.title;
      setOrder(res.data);
    });
  }, [orderId]);

  const liveChat = (
    <Box align="center" my={6} px={2} pt={4} pb={2} sx={{ border: "1px solid #DDD" }}>
      <Box mb={2}>
        <Typography variant="h5" gutterBottom>
          Need help with this order?
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Unable to get in touch with your cake artist? Got a payment related question? Get immediate assistance by clicking the button below.
        </Typography>
      </Box>
      <Button
        size="large"
        startIcon={<SmsOutlinedIcon />}
        color="primary"
        variant="outlined"
        onClick={() => {
          //window.$zoho.salesiq.visitor.name(`${order.customer?.firstName} ${order.customer?.lastName}`);
          window.$zoho.salesiq.chat.start();
        }}
      >
        Live Chat
      </Button>
      <Typography mt={1} color="textSecondary" variant="body2">
        Current wait time: 2 minutes
      </Typography>
    </Box>
  );

  if (!order) {
    return <LinearProgress />;
  } else if (order.status === "pending") {
    return (
      <Container maxWidth="sm">
        <Box mt={4}>
          <Alert severity="error">
            <AlertTitle>Waiting for Confirmation</AlertTitle>We are waiting for your cake artist to confirm your order. This may take 1-2 hours. We'll
            notify you as soon as your order is confirmed.
          </Alert>
        </Box>
        <Box my={6}>
          <Typography variant="overline" color="textSecondary">
            Order Details
          </Typography>
          <OrderDetails order={order} />
        </Box>
        <Box my={6}>
          <Typography variant="overline" color="textSecondary">
            Payment Details
          </Typography>
          <OrderPayment order={order.payment} />
        </Box>
        {liveChat}
        <Box my={6}>
          <OrderCareInstructions />
        </Box>
        <OrderCancelationPolicy
          date={order.fulfillment.date}
          total={order.payment.total}
          tip={order.payment.tip}
          delivery={order.payment.delivery}
          ts={order.ts._seconds * 1000}
        />
        <LatePaymentPolicy />
        <OrderRefundPolicy />
      </Container>
    );
  } else if (order.status === "canceled") {
    return (
      <Container maxWidth="sm">
        <Box my={4}>
          <Alert severity="error">
            <AlertTitle>Order Canceled</AlertTitle>Your order has been canceled.
          </Alert>
        </Box>
        <Box my={6}>
          <Typography variant="overline" color="textSecondary">
            Order Details
          </Typography>
          <OrderDetails order={order} />
        </Box>
        {order.fulfillment.type === "delivery" && (
          <Box my={6}>
            {order.fulfillment.type === "delivery" && <IconText icon={LocalShippingIcon} text="Curbside delivery" style={{ color: "#777" }} />}
            <OrderFulfillment order={order} />
          </Box>
        )}
        <Box my={6}>
          <Typography variant="overline" color="textSecondary">
            Payment Details
          </Typography>
          <OrderPayment order={order.payment} date={order.fulfillment.date} orderId={order.id} />
        </Box>
        {liveChat}
        <OrderCancelationPolicy
          date={order.fulfillment.date}
          total={order.payment.total}
          tip={order.payment.tip}
          delivery={order.payment.delivery}
          ts={order.ts._seconds * 1000}
        />
        <LatePaymentPolicy />
        <OrderRefundPolicy />
      </Container>
    );
  } else if (order.status === "completed") {
    return (
      <Container maxWidth="sm">
        <Box p={3} my={7} style={{ backgroundColor: "#ECECEC" }}>
          <Box align="center" mb={2}>
            <Typography variant="h4" gutterBottom>
              {order.customer.name},
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Your order is now complete. We look forward to serving you again.
            </Typography>
          </Box>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            fullWidth
            component={RouterLink}
            to={`/get-quote`}
            onClick={() => sessionStorage.setItem("utm", JSON.stringify({ utms: "reord", utmm: "txt", utmc: "1yr" }))}
          >
            GET A QUOTE
          </Button>
        </Box>
        <Box my={6}>
          <Typography variant="overline" color="textSecondary">
            Order Details
          </Typography>
          <OrderDetails order={order} />
        </Box>
        {order.fulfillment.type === "delivery" && (
          <Box my={6}>
            {order.fulfillment.type === "delivery" && <IconText icon={LocalShippingIcon} text="Curbside delivery" style={{ color: "#777" }} />}
            <OrderFulfillment order={order} />
          </Box>
        )}
        <Box my={6}>
          <Typography variant="overline" color="textSecondary">
            Payment Details
          </Typography>
          <OrderPayment order={order.payment} date={order.fulfillment.date} orderId={order.id} />
        </Box>
        {liveChat}
        <OrderRefundPolicy />
      </Container>
    );
  } else if (order.status === "confirmed" || order.status === "fulfilled") {
    return (
      <Container maxWidth="sm">
        <Box p={2} my={6} sx={{ border: "1px solid #DDD" }}>
          <Box align="center">
            <Typography variant="body1" color="textSecondary">
              {order.customer.name}, Thanks for ordering with
            </Typography>
            <Box mt={2}>
              <BakerContactCard order={order} />
            </Box>
          </Box>
        </Box>
        <Box my={6}>
          <Typography variant="overline" color="textSecondary">
            Order Details
          </Typography>
          <OrderDetails order={order} />
        </Box>
        <Box my={6}>
          {order.fulfillment.type === "delivery" && <IconText icon={LocalShippingIcon} text="Curbside delivery" style={{ color: "#777" }} />}
          {order.fulfillment.type === "pickup" && <IconText icon={LocalMallOutlinedIcon} text="Order Pickup" style={{ color: "#777" }} />}
          <OrderFulfillment order={order} />
        </Box>
        {order.invoice && <Invoices data={order.invoice} />}
        <Box my={6}>
          <Typography variant="overline" color="textSecondary">
            Payment Details
          </Typography>
          <OrderPayment order={order.payment} date={order.fulfillment.date} orderId={order.id} />
        </Box>
        {liveChat}
        <Box my={6}>
          <OrderCareInstructions />
        </Box>
        <OrderCancelationPolicy
          date={order.fulfillment.date}
          total={order.payment.total}
          tip={order.payment.tip}
          delivery={order.payment.delivery}
          ts={order.ts._seconds * 1000}
        />
        <LatePaymentPolicy />
        <OrderRefundPolicy />
      </Container>
    );
  }
}
