import React from "react";
import { Box, Grid, Typography } from "@mui/material";

export default function Highlights(props) {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={12}>
          <Box sx={{ backgroundColor: "#faf3f5", borderRadius: "4px", textAlign: "center" }} px={2} py={3}>
            <Typography variant="h4">25%</Typography>
            <Typography variant="body2" color="textSecondary">
              Increase in Employee Efficiency
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={12}>
          <Box sx={{ backgroundColor: "#faf3f5", borderRadius: "4px", textAlign: "center" }} px={2} py={3}>
            <Typography variant="h4">15%</Typography>
            <Typography variant="body2" color="textSecondary">
              Increase in Customer Retention
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={12}>
          <Box sx={{ backgroundColor: "#faf3f5", borderRadius: "4px", textAlign: "center" }} px={2} py={3}>
            <Typography variant="h4">20%</Typography>
            <Typography variant="body2" color="textSecondary">
              Increase in Order Value
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
