import React from "react";
import { Box, Grid, Link, Paper, Typography } from "@mui/material";
import { capitalize } from "../../common/util";
import moment from "moment";

export default function OrderFulfillment(props) {
  const { order } = props;

  return (
    <Paper elevation={0}>
      <Box px={2} py={4}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              {capitalize(order.fulfillment.type)} address
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {order.fulfillment?.location && (
              <Box>
                <Typography variant="body2" gutterBottom>
                  {order.fulfillment.location.street}
                  {order.fulfillment.location.unit && `, ${order.fulfillment.location.unit}`}
                </Typography>
                <Typography variant="body2">
                  {order.fulfillment.location.city}, {order.fulfillment.location.state} {order.fulfillment.location.zip}
                </Typography>
                {order.fulfillment.type === "pickup" && (
                  <Link
                    component="a"
                    underline="always"
                    target="_blank"
                    sx={{ fontSize: "12px" }}
                    href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${encodeURIComponent(
                      `${order.fulfillment.location.street}, ${order.fulfillment.location.city}, ${order.fulfillment.location.state} ${order.fulfillment.location.zip}`
                    )}`}
                  >
                    Get Directions
                  </Link>
                )}
              </Box>
            )}
            {!order.fulfillment.location && (
              <Box>
                <Typography variant="body2">{order.fulfillment.address}</Typography>
                {order.fulfillment.type === "pickup" && (
                  <Link
                    component="a"
                    underline="always"
                    target="_blank"
                    href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${encodeURIComponent(order.fulfillment.address)}`}
                  >
                    Get directions
                  </Link>
                )}
              </Box>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              {capitalize(order.fulfillment.type)} date & time
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              {moment(order.fulfillment.date).format("ddd, MMM DD, YYYY")} [{order.fulfillment.time}]
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              {capitalize(order.fulfillment.type)} instructions
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">{order.fulfillment.instructions}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
